.rtl {
    direction: rtl;
}

.rtl .pull-left {
    float:right !important;
}

.rtl .pull-right {
    float: left !important;
}

.rtl .col-xs-1,.rtl  .col-xs-10,.rtl  .col-xs-11,.rtl  .col-xs-12,.rtl  .col-xs-2,.rtl  .col-xs-3,.rtl  .col-xs-4,.rtl  .col-xs-5,.rtl  .col-xs-6,.rtl  .col-xs-7,.rtl  .col-xs-8,.rtl  .col-xs-9 {
    float: right;
}

.rtl .center-block.col-xs-1, .rtl  .center-block.col-xs-10, .rtl  .center-block.col-xs-11, .rtl  .center-block.col-xs-12, .rtl  .center-block.col-xs-2, .rtl  .center-block.col-xs-3, .rtl  .center-block.col-xs-4, .rtl  .center-block.col-xs-5, .rtl  .center-block.col-xs-6, .rtl  .center-block.col-xs-7, .rtl  .center-block.col-xs-8, .rtl  .center-block.col-xs-9 {
    float: none;
}

.panel-body .container {
  width: auto;
}
