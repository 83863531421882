* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Varela Round', sans-serif;
  width: 100vw;
  height: 100vh;
}

#root,
.App {
  width: 100%;
  height: 100%;
  background-color: #F1F3F5;
}